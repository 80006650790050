import {Helmet} from 'react-helmet-async';
import seoImage from 'src/assets/images/seo.webp';

type SeoProps = {
  title: string;
  description: string;
  favicon: string;
  image?: string;
};

const Seo = ({
  title,
  description,
  favicon,
  image = seoImage
}: SeoProps): JSX.Element => {
  const canonicalUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const ogTags: {property: string; content: string}[] = [
    {property: 'og:title', content: title},
    {property: 'og:description', content: description},
    {property: 'og:image', content: image},
    {property: 'og:url', content: canonicalUrl},
    {property: 'og:type', content: 'website'}
  ];
  const twitterTags: {name: string; content: string}[] = [
    {name: 'twitter:card', content: 'summary_large_image'},
    {name: 'twitter:title', content: title},
    {name: 'twitter:description', content: description},
    {name: 'twitter:image', content: image}
  ];
  return (
    <Helmet prioritizeSeoTags={true} defer={false}>
      <title>{title}</title>
      <link rel='icon' type='image/x-icon' href={favicon}></link>
      <link rel='canonical' href={canonicalUrl} />
      <meta name='title' content={title} />
      <meta name='description' content={description} />
      <meta itemProp='name' content={title} />
      <meta itemProp='description' content={description} />
      <meta itemProp='image' content={image} />
      {ogTags.map(
        (tag): JSX.Element => (
          <meta
            key={tag.property}
            property={tag.property}
            content={tag.content}
          />
        )
      )}
      {twitterTags.map(
        (tag): JSX.Element => (
          <meta key={tag.name} name={tag.name} content={tag.content} />
        )
      )}
    </Helmet>
  );
};

export default Seo;
