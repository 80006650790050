import {Seo} from 'src/components/shared';
import Typewriter from 'typewriter-effect';
import useTranslation from 'src/hooks/use-translation';

const Flavors = (): JSX.Element => {
  const {translate} = useTranslation('flavors');
  return (
    <>
      <Seo
        title={translate('metadata.title')}
        description={translate('metadata.description')}
        favicon='flavors.ico'
      />
      <section className='flex h-dvh w-dvw items-center justify-center bg-[#F7E7D0] font-patrick-hand-sc text-4xl text-[#7C420B] md:text-5xl'>
        <Typewriter
          options={{
            strings: [
              translate('comingSoon.english'),
              translate('comingSoon.indonesian'),
              translate('comingSoon.japanese')
            ],
            autoStart: true,
            loop: true
          }}
        />
      </section>
    </>
  );
};

export default Flavors;
