import 'src/app.css';
import 'src/react-i18next';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import Router from './router';
import {StrictMode} from 'react';

const App = (): JSX.Element => (
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>
);

export default App;
